<template>
  <div class="login">
    <div class="main">
      <img src="../assets/login_icon_logo.png" alt="" class="logo">
      <div class="main_in">
        <div class="return" @click="returnFun">返回</div>
        <div class="tab">
          <div class="tab_item" :class="tab==1?'active':''" @click="choseTab(1)">扫码登录</div>
          <div class="tab_item" :class="tab==2?'active':''" @click="choseTab(2)">手机号登录</div>
        </div>
        <div class="tab_wx" v-if="tab==1">
            <div class="wx_div">
              <div class="code_div" v-if="codeState=='default'||codeState=='overdue'">
                <img :src="wxer" alt="" class="code" v-if="wxer!=''">
                <div class="overdue" v-if="codeState=='overdue'">
                  <div>二维码已失效</div>
                  <div @click="refresh">刷新</div>
                </div>
              </div>
              <div class="wx_success" v-if="codeState=='success'">
                <img src="../assets/login_tick100.png" alt="">
                <div>登录成功</div>
              </div>
            </div>
            <div class="wx_tip"><img src="../assets/icon_wechait14.png" alt="">微信扫码登录</div>
            <div class="wx_foot">登录即代表已阅读并同意<span @click="goagreement">《用户协议》</span>和<span @click="goprivacy">《隐私政策》</span></div>
        </div>
        <div class="tab_mob" v-if="tab==2">
          <div v-if="mobState=='default'">
            <div class="form_input">
              <input type="text" v-model="phone" placeholder="请输入手机号" class="phone" maxlength="11" @blur="phoneBlur" @input="phoneInput">
            </div>
            <div class="form_input">
              <input type="text" placeholder="请输入验证码" class="yzm" maxlength="6" v-model="yzm" @input="yzmInput">
              <div class="yzm_btn" :class="yzm_btn_active?'active':''" @click="getYZM">{{yzm_btn_text}}</div>
            </div>
            <div class="form_submit" :class="form_submit_active?'active':''" @click="login">登录</div>
          </div>
          <div class="wx_success" v-if="mobState=='success'">
            <img src="../assets/login_tick100.png" alt="">
            <div>登录成功</div>
          </div>
          <div class="error" v-if="error!=''"><i class="el-icon-error"></i>{{error}}</div>
          <div class="tab_mob_tip">
            <img :src="checked?require('../assets/login_checkbox_selected.png'):require('../assets/login_checkbox_normal.png')" alt="" @click="isChecked">
            我已阅读并同意<span @click="goagreement">《用户协议》</span>和<span @click="goprivacy">《隐私政策》</span>
          </div>
        </div>
      </div>
    </div>
    <div class="foot">
      <div>COPYRIGHT © 2009-2018 经营许可证编号：<a href="http://beian.miit.gov.cn/" target="_blank">苏B2-20162058</a></div>
      <div>无锡中铠信息咨询服务有限公司 <a href="http://beian.miit.gov.cn/" target="_blank">苏ICP备12060410号-1</a></div>
    </div>
  </div>
</template>
<script>
      import WebIM from "../utils/WebIM";
  import {SendYZM,AppLogin,SaveUserScanKey,GetUserScanRecord,AppRegister} from '@/api/index'
  var regTel = /^0?1[0-9]{10}$/,timerer,timeUser,timer;
  export default {
    name: 'login',
    data() {
      return {
        tab:1,
        codeState:'default',//default默认 success登录成功 overdue过期
        phone:'',
        yzm:'',
        yzm_btn_text:'获取验证码',
        yzm_btn_active:false,
        form_submit_active:false,
        checked:false,
        mobState:'default',
        wxer:'',
        key:'',
        error:''
      }
    },
    mounted() {
      this.refresh()
      document.getElementsByClassName('login')[0].style.height=document.documentElement.clientHeight+'px'
      window.addEventListener("popstate",function(e){
        clearInterval(timeUser)
        clearTimeout(timerer)
      },false)
    },
    beforeRouteLeave(to,from,next){
      clearInterval(timeUser)
      clearTimeout(timerer)
      next()
    },
    methods: {
      refresh(){
        this.codeState='default'
        var arr = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
                    num = "";
        for (var i = 0; i < 5; i++) {
            num += arr[parseInt(Math.random() * 10)];
        }
        SaveUserScanKey({"randomId":num}).then(res=>{
          if(res.state==0){
            this.wxer='data:image/png;base64,'+res.data.base64
            this.key=res.data.key
            this.count60s()
            this.getUser()
          }
        })
      },
      getUser(){
        timeUser=setInterval(()=>{
          GetUserScanRecord({'key':this.key}).then(res=>{
            console.log(res)
            if(res.state==0){
              clearInterval(timeUser)
              clearTimeout(timerer)
              localStorage.setItem('user',JSON.stringify(res.data.UserInfo))
              this.mobState='success'
              this.toPage()
            }
          })
        },1000)
      },
      count60s(){
        timerer=setTimeout(()=>{
          this.codeState='overdue'
          clearInterval(timeUser)
        },60*1000)
      },
      goagreement(){
        this.$router.push({ path: '/agreement'})
      },
      goprivacy(){
        this.$router.push({ path: '/privacy'})
      },
      returnFun(){
        this.$router.back(0)
      },
      choseTab(num){
        this.tab=num
        if(num==2){
          clearInterval(timeUser)
        }else{
          this.refresh()
        }
      },
      phoneBlur(){
        if(this.phone==''){
          this.yzm_btn_active=false
        }else if(regTel.test(this.phone)){
          this.yzm_btn_active=true
          this.error=''
        }else{
          this.yzm_btn_active=false
          this.error='请输入正确的手机号'
        }
      },
      phoneInput(e){
        this.phone=this.phone.replace(/[^\d]/g,'')
        this.yzm_btn_text='获取验证码'
        this.yzm_btn_active=false
        if(regTel.test(this.phone)){
           this.yzm_btn_active=true
        }else{
          this.yzm_btn_active=false
        }
        this.check()
        clearInterval(timer)
      },
      getYZM(){
        var that=this;
        if(this.yzm_btn_active){
          var loading =this.$loading();
          var data={
            "memberMoblie": this.phone
          }
          SendYZM(data).then(res=>{
            loading.close();
            if(res.state==0){
              this.$message('验证码已发送')
              this.yzm_btn_active=false
              var time = 59;
               timer = setInterval(function() {
                that.yzm_btn_text=time+'s倒计时'
                time--
                if (time < 0) {
                  clearInterval(timer)
                  that.yzm_btn_text='重新获取'
                  that.yzm_btn_active=true
                }
              }, 1000)
            }else{
              this.$message(res.des)
            }
          })
          
        }
      },
      yzmInput(){
        this.yzm=this.yzm.replace(/[^\d]/g,'')
        this.check()
      },
      check(){
        if(regTel.test(this.phone)&&this.yzm!=''){
          this.form_submit_active=true
        }else{
          this.form_submit_active=false
        }
      },
      isChecked(){
        this.checked=!this.checked
        this.check()
      },
      login(){
        if(this.form_submit_active){
          if(!this.checked){
            // this.$message({
            //   type:'error',
            //   message:'请勾选并同意用户协议和隐私政策',
            //   duration:30000
            // })
            this.error='请勾选并同意用户协议和隐私政策'
          }else{
            this.error=''
            var loading =this.$loading();
            var data={
              "memberMoblie": this.phone,
              "yzm": this.yzm
            }
            AppLogin(data).then(res=>{
              console.log(res)
              if(res.state==0){
                loading.close();
                localStorage.setItem('user',JSON.stringify(res.data.UserInfo))
                this.mobState='success'
                this.toPage()
              }else{
                if(res.des=='未注册'){
                  this.randomName().then(res=>{
                    var data={
                      "memberMoblie": this.phone,
                      "yzm": this.yzm,
                      "name": res,
                      "sex": "男",
                      "memberComeFrom": localStorage.getItem('orderfrom') ? localStorage.getItem('orderfrom') :"pc"
                    }
                    AppRegister(data).then(res=>{
                      loading.close();
                      if(res.state==0){
                        localStorage.setItem('user',JSON.stringify(res.data.UserInfo))
                        this.mobState='success'
                        this.toPage()
                      }else{
                        this.error=res.des
                      }
                    })
                  })
                }else{
                  loading.close();
                  this.error=res.des
                }
              }
            })
          }
        }
        
      },
      randomName(){
        return new Promise((resolve,reject)=>{
           var AZ = 'QWERTYUIOPASDFGHJKLZXCVBNM';
            var az = AZ.toLocaleLowerCase();
            az += '1234567890';
            var name = AZ.substr(Math.random() * AZ.length, 1)


            for (var i = 0; i < 6; i++) {
                name += az.substr(Math.random() * az.length, 1)
            }
            resolve(name)
        })
      },
      toPage(){
        this.loginIM()
        if(this.$route.query.to){
          if(this.$route.query.to=='ask'){
            this.$router.push(this.$route.query.to+'?id='+this.$route.query.id)
          }else if(this.$route.query.to=='center'&&this.$route.query.id){
            this.$router.push(this.$route.query.to+'?id='+this.$route.query.id)
          }else{
            this.$router.push(this.$route.query.to)
          }
        }else{
          setTimeout(()=>{
            this.$router.back(0)
          },2000)
        }
      }
    }
  }

</script>

<style scoped lang="scss">
  .login{
    background: url('../assets/login_bg.png') no-repeat;
    background-size: 100% 100%;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    min-width: 940px;
    min-height: 850px;
    .main{
      width: 940px;
      margin: 0 auto;
      padding-top: 60px;
      .logo{
        margin-bottom: 24px;
      }
      .main_in{
        width: 340px;
        height: 480px;
        background: #FFFFFF;
        border-radius: 10px;
        margin: 0 auto;
        padding: 50px 30px 0;
        position: relative;
        .return{
          font-size: 14px;color: #AAAAAA;
          position: absolute;
          top: 14px;
          right: 14px;
          cursor: pointer;
        }
        .tab{
          border-bottom: 1px solid #aaa;
          .tab_item{
            font-size: 18px;color: #AAAAAA;
            padding-bottom: 18px;
             border-bottom: 2px solid #fff;
             display: inline-block;
             cursor: pointer;
          }
          .active{
             border-bottom: 2px solid #000;
              color: #000000;
          }
          .tab_item:first-child{
            margin-right: 29px;
          }
        }
        .tab_wx{
          .wx_div{
            width: 260px;
            height: 260px;
            margin: 40px auto 27px;
            
            .code_div{
              position: relative;
              .code{
                width: 246px;
                height: 246px;
                border-radius: 20px;
                border: 7px solid #F8F8F8;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
              }
              .overdue{
                width: 260px;
                height: 260px;
                background: rgba(0,0,0,0.7);
                border-radius: 20px;
                position: absolute;
                top: 0;
                left: 0;
                text-align: center;
                div:nth-child(1){
                  font-size: 18px;color: #FFFFFF;padding-top: 120px;
                }
                div:nth-child(2){
                  font-size: 18px;color: #FFFFFF;
                  width: 70px;
                  height: 30px;
                  background: #21D0C8;margin: 0 auto;cursor: pointer;
                  border-radius: 15px;margin-top: 52px;line-height: 30px;
                }
              }
            }
          }
          
          .wx_tip{
            text-align: center;margin-bottom: 23px;
            font-size: 18px;color: #666666;
            img{
              margin-right: 6px;
            }
          }
          .wx_foot{
            text-align: center;
            font-size: 12px;color: #AAAAAA;
            span{
              cursor: pointer;color: #21D0C8;
            }
          }
        }
        .wx_success{
              text-align: center;
              img{
                margin-bottom: 20px;padding-top: 60px;
              }
              div{
                font-size: 18px;
                font-weight: bold;
                color: #000000;
              }
            }
        .tab_mob{
          padding-top: 49px;
          .form_input{
            width: 100%;
            height: 54px;
            background: #F5F5F8;
            border: 1px solid #D7D7D7;
            border-radius: 6px;
            input{
             height: 54px;
              border: 0;background: #F5F5F8;font-size: 16px;
              border-radius: 6px;
              padding: 0 20px;color: #000;
            }
            input:focus{
              background:#fff;
            }
            .phone{
              width: 300px;
            }
            .yzm{
              width: 200px;
            }
            .yzm_btn{
              display: inline-block;
              width: 100px;
              text-align: center;
              line-height: 54px;font-size: 16px;color: #AFF0F7;cursor: pointer;
            }
            .yzm_btn.active{
              color: #21D0C8;
            }
          }
          .form_input:first-child{
              margin-bottom: 21px;
          
          }
          .form_input:nth-child(2){
            margin-bottom: 41px;
          }
          .form_submit{
             width: 100%;
            height: 54px;background: #F5F5F8;
            border-radius: 6px;font-size: 18px;color: #AAAAAA;text-align: center;
              line-height: 54px;cursor: pointer;
          }
          .form_submit.active{
            background: #21D0C8;color: #FFFFFF;
          }
          .tab_mob_tip{
            position: absolute;
            bottom: 38px;
            left: 0;
            width: 100%;
            text-align: center;
            font-size: 12px;color: #AAAAAA;
            img{
              margin-right: 6px;vertical-align: middle;cursor: pointer;
              position: relative;
              top: -2px;
            }
            span{
              color: #21D0C8;cursor: pointer;
            }
          }
        }
      }
    }
    .foot{
      text-align: center;
      font-size: 10px;color: #FFFFFF;
      position: absolute;
      bottom: 112px;
      width: 100%;
      a{
        color: #FFFFFF;text-decoration: none;
      }
    }
    .error{
      font-size: 12px;
      margin-top: 40px;
      color: #F56C6C;
      text-align: center;
      i{
        font-size: 16px;
        margin-right: 10px;
        vertical-align: middle;
      }
    }
    
  }

</style>
<style>
.el-message {
      left: 50%;
      top: 50%!important;
      transform: translate(-50%, -50%);
      margin: 0px !important;
    }

</style>